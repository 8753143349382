div {
  display: grid;
}

select {
  justify-self: center;
  width: 150px;
}

.start-game-button {
  margin-top: 15px;
}

.header {
  margin: 0 0 0 0;
  padding: 0;
  text-align: center;
}

.select-center {
  text-align: center;
}
