p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif;
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

.title {
  padding: 0;
  margin: 0;
  text-align: center;
  font-family: "Open Sans", sans-serif;
}
