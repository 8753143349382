.centered {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.h-v-centered {
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 240px;
  min-width: 240px;
  transform: translateX(-50%) translateY(-50%);
}

@media (max-width: 500px) and (min-width: 350px) {
  .h-v-centered {
    top: 50%;
  }
}
