@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
.centered {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.h-v-centered {
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 240px;
  min-width: 240px;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
}

@media (max-width: 500px) and (min-width: 350px) {
  .h-v-centered {
    top: 50%;
  }
}

.team-text,
.round-text,
.score-text {
  text-align: center;
  padding: 0;
  margin: 10px;
}

.begin-button {
  margin-top: 15px;
}

.h-v-centered {
  top: 50%;
}

hr {
  width: 100%;
  margin: 5;
  padding: 0;
}

.image-button {
  background: transparent;
  border: 0;
  grid-row-start: 1;
  width: inherit;
  cursor: pointer;
  outline: 0;
}

.image-button > img {
  width: inherit;
  height: inherit;
}

.correct-button {
  justify-self: right;
  margin-right: 10px;
  grid-column-start: 1;
}

.pass-button {
  justify-self: left;
  margin-left: 10px;
  grid-column-start: 2;
}

.button-disabled {
  opacity: 0.5;
}

.column-centered {
  justify-self: center;
}

.root .button-grid-game {
  grid-template-columns: 50px 50px;
  grid-column: 2;
  grid-row: 1;
  grid-column-gap: 25px;
}

.the-word {
  text-align: center;
  font-family: "Arial";
  text-transform: capitalize;
}

.time-left-container {
  margin-top: 15px;
  justify-self: center;
  text-align: center;
  -webkit-text-size-adjust: 80%;
     -moz-text-size-adjust: 80%;
      -ms-text-size-adjust: 80%;
          text-size-adjust: 80%;
}

.hidden {
  display: none !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif;
}

.title {
  padding: 0;
  margin: 0;
  text-align: center;
  font-family: "Open Sans", sans-serif;
}

div {
  display: grid;
}

select {
  justify-self: center;
  width: 150px;
}

.start-game-button {
  margin-top: 15px;
}

.header {
  margin: 0 0 0 0;
  padding: 0;
  text-align: center;
}

.select-center {
  text-align: center;
}

.selected {
  border-radius: 35px;
  box-shadow: 0 0 10px 5px cornflowerblue;
}

.not-selected {
  border-radius: 35px;
  box-shadow: 0 0 5px 0px black;
}

.starting-description {
  opacity: 65%;
  text-align: center;
}

/* Grid stuff */
.text-grid {
  grid-area: "text";
  text-align: center;
  grid-column: 1 / span 2;
}

.image-grid {
  grid-area: "image";
  grid-row-start: 2;
  margin-bottom: 15px;
  cursor: pointer;
}

.swe-flag {
  justify-self: right;
}

.button-grid-container {
  display: grid;
  grid-template-columns:
    "text text"
    "image image";
  grid-column-gap: 25px;
  grid-row-gap: 15px;
  margin-bottom: 15px;
} /* Grid stuff */

.snackbar-span {
  align-items: center;
  display: flex;
}

.snackbar-span > span {
  margin-left: 15px;
}

.h1-404 {
  text-align: center;
  font-family: "Open Sans";
  font-size: 50px;
}

.a-404 {
  justify-self: center;
  text-decoration: none;
}

