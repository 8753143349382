.h1-404 {
  text-align: center;
  font-family: "Open Sans";
  font-size: 50px;
}

.a-404 {
  justify-self: center;
  text-decoration: none;
}
