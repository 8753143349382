.selected {
  border-radius: 35px;
  box-shadow: 0 0 10px 5px cornflowerblue;
}

.not-selected {
  border-radius: 35px;
  box-shadow: 0 0 5px 0px black;
}

.starting-description {
  opacity: 65%;
  text-align: center;
}

/* Grid stuff */
.text-grid {
  grid-area: "text";
  text-align: center;
  grid-column: 1 / span 2;
}

.image-grid {
  grid-area: "image";
  grid-row-start: 2;
  margin-bottom: 15px;
  cursor: pointer;
}

.swe-flag {
  justify-self: right;
}

.button-grid-container {
  display: grid;
  grid-template-columns:
    "text text"
    "image image";
  grid-column-gap: 25px;
  grid-row-gap: 15px;
  margin-bottom: 15px;
} /* Grid stuff */

.snackbar-span {
  align-items: center;
  display: flex;
}

.snackbar-span > span {
  margin-left: 15px;
}
